import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md"
import UserInfo from "./userInfo";

function ConsultationDropDown(props) {
  const [showUserInfo, setShowUserInfo] = useState(false)
  
  return (
    <>
      <div className="dropDown">
        <span>{props.title}</span>
        <MdKeyboardArrowDown className={`icon ${showUserInfo ? "show" : ""}`} onClick={() => setShowUserInfo(!showUserInfo)} />
      </div>    
     
      { showUserInfo ? (<UserInfo />) : '' } 
      { showUserInfo ? (<UserInfo />) : '' }
    </>
  );
}

export default ConsultationDropDown;