import { Icon } from "@iconify/react";

function ExpertBox(props) {
    return (
        <div className="block">
            <div className="preview">
                <div className="text">
                    <div className="avatar"></div>
                </div>
            </div>
            <div className="top-bar">
                <span>3670 consult</span>
                <div className="icons">
                    <Icon icon="mdi:telephone" color="#FFF" />
                    <Icon icon="eva:message-circle-fill" color="#FFF" />
                    <Icon icon="mdi:email" color="#FFF" />
                </div>
            </div>
            <div className="title">Nom<br/>Categorie</div>
            <div className="stars">
                <Icon icon="material-symbols:star" color="#F5CE00" height={24} width={24} />
                <Icon icon="material-symbols:star" color="#F5CE00" height={24} width={24} />
                <Icon icon="material-symbols:star" color="#F5CE00" height={24} width={24} />
                <Icon icon="material-symbols:star" color="#F5CE00" height={24} width={24} />
                <Icon icon="material-symbols:star" color="#F5CE00" height={24} width={24} />
            </div>
            <p>Une petite description du profile</p>
            <div className="btn">
                {
                    props.disponibily === "dispo" ? (<button>Je consulte</button>) : (
                        props.disponibily === "horsLigne" ? <button className="disable">horsLigne</button> : <button className="disable">De retour à {props.disponibily}</button>
                    )
                }
                
            </div>
        </div>
    );
}

export default ExpertBox;