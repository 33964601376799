import { LayoutClient } from "../../components/layout/layout";
import MeetingTable from "../../components/tables/meetingTable";

function MyMeetingClient() {
  return (
    <LayoutClient id="myMeetingClient">
      <h2>Mes Randez-Vous</h2>

      <MeetingTable />
    </LayoutClient>
  );
}

export default MyMeetingClient;