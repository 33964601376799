import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function ConsultationBox(props) {
  return (
    <div className="consultation-box">
      <h4 className="title">{ props.title }</h4>
      <div className="tableContainer">
        <Table>
          <Thead>
            <Tr>
              {
                Object.keys(props.data).map(elem => {
                  return (
                    <Th>{elem}</Th>
                  )
                })
              }
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              {
                Object.keys(props.data).map(elem => {
                  return (
                    <Td>{props.data[elem]}</Td>
                  )
                })
              }
            </Tr>
          </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default ConsultationBox;