import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

import { Calendar } from "primereact/calendar"
import { useState } from "react";
import AnalyseexpertTable from "../../components/tables/analyseexpertTable";

function AnalyseExpertPage() {
  const [dateRange, setDateRange] = useState(null);

  return (
    <Layout id="analyseExpert">
      <div className="top-bar">
        <h2>Statistiques experts</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Experts</Link>
        </div>
      </div>

      <div className="content-box">
        <div className="filter">
          <div className="date">
            <label htmlFor="inputRange">Date</label>
            <Calendar
              dateFormat="dd MM, yy"
              value={dateRange}
              onChange={(e) => setDateRange(e.value)}
              selectionMode="range"
              id="inputRange"
            />
          </div>
          <button className="recherche">Rechercher</button>
        </div>
        
        <AnalyseexpertTable />
      </div>
    </Layout>
  );
}

export default AnalyseExpertPage;
