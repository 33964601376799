import { HiOutlineUserCircle } from "react-icons/hi";

function MailBox(props) {
  return (
    <div className="mail-box">
      <div className="top-bar">
        <div className="user">
          <HiOutlineUserCircle />
          <span className="user-name">{ props.userName }</span>
        </div>
        <span className="date">{ props.date }</span>
      </div>

      <div className="title">{ props.title }</div>
      <p>{ props.text }</p>

      <div className="btn">
        <button>Répondre</button>
      </div>
    </div>
  );
}

export default MailBox;