import React, { useState, useEffect, useRef } from 'react';
import { countries } from './countries';
import { Icon } from '@iconify/react';

const PhoneNumberInput = () => {
  const [selectedCountry, setSelectedCountry] = useState(countries[72]);
  const [showCountryDropdown, setShowCountryDropdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowCountryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setShowCountryDropdown(false);
  };

  const handlePhoneNumberChange = (event) => {
    const formattedPhoneNumber = event.target.value.replace(/\D/g, '');
    if (formattedPhoneNumber.length <= 15) {
      setPhoneNumber(formattedPhoneNumber);
    }
  };

  const toggleCountryDropdown = () => {
    setShowCountryDropdown(!showCountryDropdown);
  };

  return (
    <div className="phone-number-input">
      <div className="country-display" onClick={toggleCountryDropdown}>
        <img src={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png`} alt={selectedCountry.name} />
        <span>{selectedCountry.dial_code} <Icon icon="material-symbols:arrow-drop-down" /></span>
      </div>
      {showCountryDropdown && (
        <div className="country-dropdown" ref={dropdownRef}>
          {countries.map((country) => (
            <div
              key={country.code}
              className="country-dropdown-item"
              onClick={() => handleCountryChange(country)}
            >
              <img src={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png`} alt={country.name} />
              <span>{`${country.name} (${country.dial_code})`}</span>
            </div>
          ))}
        </div>
      )}
      <div className="phone-input-wrapper">
        <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} placeholder="" />
      </div>
    </div>
  );
};

export default PhoneNumberInput;
