import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import Layout from "../../components/layout/layout";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

import { Calendar } from "primereact/calendar"
import { useState } from "react";
import BoxPackage from "../../components/utils/boxPackage";

function PurchasePackagePage() {
  const [dateRange, setDateRange] = useState(null)

  return (
    <Layout id="purchasePackage">
      <div className="top-bar">
        <h2>Achats forfaits</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Achats forfaits</Link>
        </div>
      </div>

      <div className="filter-bar">
        <div className="date">
          <label htmlFor="inputRange">Date</label>
          <Calendar
            dateFormat="dd MM, yy"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            id="inputRange"
          />
        </div>
        <button className="recherche">Rechercher</button>
      </div>
      
      <div className="content-box">
        <h3>VENTES SUR LA PÉRIODE DU 01/03/2023</h3>

        <div className="container">
          <BoxPackage 
            title="CA sur 30 jours"
            icon="tabler:chart-infographic"
            bigTitle="73.3€"
            pourcent="-73.4%"
            iconPourcent="material-symbols:arrow-downward"
            color="#C10000"
          />
          <BoxPackage 
            title="Minutes sur 30 jours"
            icon="material-symbols:graphic-eq-rounded"
            bigTitle="10 min"
            pourcent="-76.5%"
            iconPourcent="material-symbols:arrow-downward"
            color="#C10000"
          />
          <BoxPackage 
            title="Minutes offertes sur 30 jours"
            icon="material-symbols:graphic-eq-rounded"
            bigTitle="0 min"
            pourcent="100%"
            iconPourcent="material-symbols:arrow-upward"
            color="#81C313"
          />
          <BoxPackage 
            title="Consommation sur 30 jours"
            icon="material-symbols:graphic-eq-rounded"
            bigTitle="21h 27 min"
            pourcent="-76.2%"
            iconPourcent="material-symbols:arrow-downward"
            color="#C10000"
          />
          <BoxPackage 
            title="Remboursement sur 30 jours"
            icon="material-symbols:graphic-eq-rounded"
            bigTitle="0 €"
            pourcent="-100%"
            iconPourcent="material-symbols:arrow-downward"
            color="#C10000"
          />
        </div>
      </div>
    </Layout>
  );
}

export default PurchasePackagePage;
