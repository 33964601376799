import ExpertInfoBox from "../../components/expert/expertInfoBox";
import { LayoutExpert } from "../../components/layout/layout";
import HomeExpertTable from "../../components/tables/homeExpertTable";

function HomePageExpert() {
  return (
    <LayoutExpert id="homeExpert">
      <h3>Expert</h3>

      <ExpertInfoBox />

      <div className="tableContainer">
        <HomeExpertTable 
          data={
            [
              [0, 0],
              [0, 0]
            ]
          }
        />
        
        <HomeExpertTable 
          data={
            [
              [0, 0],
              [0, 0]
            ]
          }
        />
      </div>
    </LayoutExpert>
  );
}

export default HomePageExpert;