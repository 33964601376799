import { LayoutClient } from "../../components/layout/layout";

function ProfileClient() {
  return (
    <LayoutClient id="profileClient">
      <h2>Mon Profil</h2>
    
      <div className="box">
        <div className="ligne">
          <span>Pseudo : </span>
          <span>Test</span>
        </div>
        <div className="ligne">
          <span>E-mail : </span>
          <span>test@gmail.com</span>
        </div>
        <div className="ligne">
          <span>Téléphone principal : </span>
          <span>0000000000</span>
        </div>
        <div className="ligne">
          <span>Nom complet : </span>
          <span>Test</span>
        </div>
      </div>

      <div className="btn-group">
        <button>Modifier mon profil</button>
        <button>Supprimer mon compte</button>
      </div>
    </LayoutClient>
  );
}

export default ProfileClient;