import { LayoutExpert } from "../../components/layout/layout";
import NavigateBar from "../../components/tables/navigateBar";
import StatistiqueTable from "../../components/tables/statistiqueTable";
import CustomSelect from "../../components/utils/CustomSelect";

function StatistiqueExpert() {
  return (
    <LayoutExpert id="expertStatistique">
      <h2>Statistiquement</h2>
      
      <div className="container">
        <div className="box">
          <div className="top-bar">GAIN</div>
          <div className="area">
            <div className="title">16.72</div>
          </div>
        </div>
        <div className="box">
          <div className="top-bar">GAIN</div>
          <div className="area">
            <span className="sub-title">Taux décroché (Nb appel manqué)</span>
            <span className="title-2">100%(0)</span>
            <div className="multi-tags">
              <div className="tag-container">
                <div className="tag">TMC</div>
                <span>25.57</span>
              </div>
              <div className="tag-container">
                <div className="tag">Etoile</div>
                <span>0</span>
              </div>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="top-bar">GAIN</div>
          <div className="area">
            <span className="sub-title">Nombre consultation</span>
            <span className="title-2">6</span>
            <div className="multi-tags">
              <div className="tag-container">
                <div className="tag">Tel</div>
                <span>100%</span>
              </div>
              <div className="tag-container">
                <div className="tag">Chat</div>
                <span>0%</span>
              </div>
              <div className="tag-container">
                <div className="tag">Mail</div>
                <span>0%</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="table-bar">
        <label>Filter</label>
        <input />
        <CustomSelect 
          options={[
            {value: 0, label: "type"},
            {value: 1, label: "name"},
            {value: 1, label: "date"},
            {value: 1, label: "heure"},
          ]}
        />
      </div>

      <StatistiqueTable />
      <NavigateBar />
    </LayoutExpert>
  );
}

export default StatistiqueExpert; 