function LoginPage() {
	return (
		<div id="loginPage">
			<div className="content">
				<img src="/assets/logo.svg"/>
				<h2>Content de te revoir!</h2>
				<span className="sub-title">Entrez vos données</span>

				<form>
					<div className="form-group">
						<label>E-mail</label>
						<input placeholder="Entrer votre email" type="email"/>
					</div>
					<div className="form-group">
						<label>password</label>
						<input placeholder="Entrer votre password" type="password"/>
					</div>
					<input type="submit" value="LOGIN" className="submit"/>
				</form>

				<span className="nouveauClient">Nouveau client? Inscrivez-vous</span>
				<a href="/register" className="btn">Inscription</a>
				<a className="link">Revenir au site</a>
			</div>
			<div className="bg">
				<img src="/assets/logo.svg"/>
			</div>
		</div>
  );
}

export default LoginPage;