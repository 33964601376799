import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import ClientlistTable from "../../components/tables/clientList";
import CustomSelect from "../../components/utils/CustomSelect";

function ClientListPage() {
  return (
    <Layout id="clientList">
      <div className="top-bar">
        <h2>Client list</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Experts</Link>
        </div>
      </div>
      <div className="form">
        <input placeholder="Client" />
        <CustomSelect 
            options={[
              {value: 0, label: "Tous"}
            ]}
        />
        <Calendar />
        <Calendar />
        <CustomSelect 
            options={[
              {value: 0, label: "Actifs"},
              {value: 1, label: "Passif"}
            ]}
        />
        <CustomSelect 
            options={[
            ]}
        />
        <button>Recherche</button>
      </div>

      <div className="nbResult">15693 clients trouvés</div>

      <ClientlistTable />
    </Layout>
  );
}

export default ClientListPage;
