import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import Advice from "../../components/advice/advice";
import CustomSelect from "../../components/utils/CustomSelect";

function AdvicePage() {
  return (
    <Layout id="advice">
      <div className="top-bar">
        <h2>Avis</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Clients</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Avis</Link>
        </div>
      </div>

      <div className="content-box">
        <div className="filter">
          <div className="form-group">
            <label>Période</label>
            <CustomSelect 
              options={[
                {value: 0, label: "N’importe quand"},
                {value: 1, label: "aujourd'hui"},
                {value: 2, label: "hier"},
                {value: 3, label: "demain"}
              ]}
            />
          </div>
          <div className="form-group">
            <label>Auteur</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>État</label>
            <CustomSelect 
              options={[
                {value: 0, label: "En attente"},
                {value: 1, label: "Actif"},
                {value: 2, label: "Fini"}
              ]}
            />
          </div>
          <div className="form-group">
            <label>Expert</label>
            <CustomSelect 
              options={[
                {value: 0, label: "Tous"},
                {value: 1, label: "Jack"},
                {value: 2, label: "Lucas"}
              ]}
            />
          </div>
          <div className="form-group">
            <label>Note</label>
            <CustomSelect 
              options={[
                {value: 0, label: "Toutes"},
                {value: 5, label: ">5"},
                {value: 4, label: ">4"},
                {value: 3, label: ">3"},
                {value: 2, label: ">2"},
                {value: 1, label: ">1"}
              ]}
            />
            <button className="recherche">Rechercher</button>
          </div>
          <button className="nouveau">
            <span className="plus">+</span>
            <span className="text">Nouveau</span>
          </button>
        </div>

        <Advice 
          title="#135126 - Expert"
          tag="(En attente)"
          commentaire="Commentaire du 08/03/2023 21:14:10 (consultation du 08/03/2023 16:06:18)"
        />
        <Advice 
          title="#135126 - Expert"
          tag="(En attente) - (Réponse en attente)"
          commentaire="Commentaire du 08/03/2023 18:37:25 (consultation du 08/03/2023 08:52:23)"
        />

      </div>
    </Layout>
  );
}

export default AdvicePage;
