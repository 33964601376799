import { Icon } from "@iconify/react";

function ProfileBar() {
    return (
        <section id="profileBar">
            <div className="picture"></div>
            <div className="info">
                <div className="details">
                    <div>
                        <h3>Maud</h3>
                        <button>Je consult maintenant</button>
                    </div>
                    <div className="avis">
                        <span>512 avis</span>
                        <div className="stars">
                            <Icon icon="material-symbols:star" />
                            <Icon icon="material-symbols:star" />
                            <Icon icon="material-symbols:star" />
                            <Icon icon="material-symbols:star" />
                            <Icon icon="material-symbols:star" />
                        </div>
                        <span>7487 consultations</span>
                    </div>
                </div>
                <div className="nav">
                    <div>
                        <Icon icon="material-symbols:supervisor-account-sharp" />
                        <span>Presentation </span>
                    </div>
                    <div>
                        <Icon icon="tabler:packge-export" />
                        <span>Forfaits </span>
                    </div>
                    <div>
                        <Icon icon="ic:outline-mode-comment" />
                        <span>Commentaires </span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProfileBar;