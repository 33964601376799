import { AiFillStar } from "react-icons/ai";

function AdviceBox(props) {
  return (
    <div className="advice-box">
      <div className="top-bar">
        <span className="title">
          <span className="purple-text">{props.id}</span> - {props.title}
        </span>
        <div className="stars">
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
        </div>
      </div>

      <p>{props.children}</p>
      
      <hr />

      {
        props.alert ? (
          <div className="alert-box">
            <span>{props.alert}</span>
          </div>
        ) : (
          <div className="form-group">
            <textarea />
            <div className="btn">
              <button>Répondre</button>
            </div>
          </div>   
        )
      }
    </div>
  );
}

export default AdviceBox;