import { Icon } from "@iconify/react";

function ExpertBox(props) {
  return (
    <div className="expert-box">
      <span className="title">{props.title}</span>
      <div className="desc">
        <div className="picture"><Icon icon="mdi:account-circle-outline" /></div>
        <div>Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text.</div>
      </div>
      <div className="btn-group">
        <button>
          <Icon icon="bi:telephone" />
          <span>Appeler</span>
        </button>
        <button>
          <Icon icon="material-symbols:chat-bubble-outline-rounded" />
          <span>E-mail</span>
        </button>
        <button>
          <Icon icon="material-symbols:attach-email-outline" />
          <span>Chat</span>
        </button>
        <button>
          <Icon icon="teenyicons:appointments-outline" />
          <span>Rendez-Vous</span>
        </button>
      </div>
    </div>
  );
}

export default ExpertBox;