import { AiFillStar } from "react-icons/ai";

function Advice(props) {
  return (
    <div className="advice">
      <div className="top-bar-advice">
        <h3>{props.title} <span className="purple-text">{props.tag}</span></h3>
        <div className="stars">
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
          <AiFillStar color="#E7CE4C"/>
        </div>
      </div>
      <div className="second-bar-advice">
        <div>
          <span className="green-text">Par Client</span>
          <span className="grey-text">Test de commentaire</span>
        </div>
        <span className="commentaire">{props.commentaire}</span>
      </div>
      <hr/>
      <div className="btn-group-advice">
        <button className="modify">Modifier</button>
        <button className="reject">Rejeter</button>
        <button className="publish">Publier</button>
      </div>
    </div>
  );
}

export default Advice;