import { Icon } from "@iconify/react";
import NavLink from "../components/path/profile/navLink";
import ProfileBar from "../components/path/profile/profileBar";

function ProfilePage() {
    return (
        <div id="profilePath">
            <img src="/assets/logo.svg"/>
            <a className="back-btn" href="/experts">
                <Icon icon="material-symbols:arrow-back"/>
            </a>

            <div className="container">
                <ProfileBar />
                <NavLink selected={0} />
                <div className="col2-1">
                    <div className="box">
                        <p>A propos de</p>
                    </div>
                    <div className="box">
                        <span className="title">Lancer une consultation</span>
                        <div className="bar">
                            <Icon icon="ic:outline-access-time-filled" />
                            <span>Yanis téléphone au forfait</span>
                        </div>
                        <div className="bar green">
                            <Icon icon="fa6-solid:calendar-days" />
                            <span>rendez vous</span>
                        </div>
                    </div>
                </div>
                <NavLink selected={1} />
                <div className="col2-2">
                    <div className="box">
                        <div className="block">
                            <span className="top-bar">Forfait Flash</span>
                            <div className="list">
                                <Icon icon="ic:sharp-access-time" />
                                <span>10 Minutes<br/>pour 20 $</span>
                            </div>
                            <span className="footer-bar">Choisir du forfait</span>
                        </div>
                        <div className="block">
                            <span className="top-bar">Forfait Flash</span>
                            <div className="list">
                                <Icon icon="ic:sharp-access-time" />
                                <span>10 Minutes<br/>pour 20 $</span>
                            </div>
                            <span className="footer-bar">Choisir du forfait</span>
                        </div>
                        <div className="block">
                            <span className="top-bar">Forfait Flash</span>
                            <div className="list">
                                <Icon icon="ic:sharp-access-time" />
                                <span>10 Minutes<br/>pour 20 $</span>
                            </div>
                            <span className="footer-bar">Choisir du forfait</span>
                        </div>
                        <div className="block">
                            <span className="top-bar">Forfait Flash</span>
                            <div className="list">
                                <Icon icon="ic:sharp-access-time" />
                                <span>10 Minutes<br/>pour 20 $</span>
                            </div>
                            <span className="footer-bar">Choisir du forfait</span>
                        </div>
                        <div className="block">
                            <span className="top-bar">Forfait Flash</span>
                            <div className="list">
                                <Icon icon="ic:sharp-access-time" />
                                <span>10 Minutes<br/>pour 20 $</span>
                            </div>
                            <span className="footer-bar">Choisir du forfait</span>
                        </div>
                        <div className="block">
                            <span className="top-bar">Forfait Flash</span>
                            <div className="list">
                                <Icon icon="ic:sharp-access-time" />
                                <span>10 Minutes<br/>pour 20 $</span>
                            </div>
                            <span className="footer-bar">Choisir du forfait</span>
                        </div>
                    </div>
                    <div className="box">
                        <h3>Disponiblites</h3>
                        <div className="ligne">
                            <div>
                                <span>Mercredi</span>
                                <span>(19/04)</span>
                            </div>
                            <div>
                                <span>18:00 - 20:00</span>
                            </div>
                        </div>
                        <div className="ligne">
                            <div>
                                <span>Jeudi</span>
                                <span>(20/04)</span>
                            </div>
                            <div>
                                <span>11:00 - 13:00</span>
                            </div>
                        </div>
                        <div className="ligne">
                            <div>
                                <span>Vendredi</span>
                                <span>(20/04)</span>
                            </div>
                            <div>
                                <span>18:00 - 20:00</span>
                            </div>
                        </div>
                        <div className="ligne">
                            <div>
                                <span>Samedi</span>
                                <span>(22/04)</span>
                            </div>
                            <div>
                                <span>11:00 - 13:00</span>
                            </div>
                        </div>
                        <div className="ligne">
                            <div>
                                <span>Dimanche</span>
                                <span>(23/04)</span>
                            </div>
                            <div>
                                <span>19:00 - 22:00</span>
                            </div>
                        </div>
                    </div>
                </div>
                <NavLink selected={2} />
                <div className="col2-3">
                    <div className="box">
                        <span className="title">Commentaires</span>
                        <div>
                            <div className="badge">94,44%</div>
                        </div>
                        <div className="stars-box">
                            <div className="stars">
                                <Icon icon="material-symbols:star" />
                                <Icon icon="material-symbols:star" />
                                <Icon icon="material-symbols:star" />
                                <Icon icon="material-symbols:star" />
                                <Icon icon="material-symbols:star" />
                            </div>
                            <span>02/04/2023</span>
                        </div>
                        <p>Merci pour cet entretien court mais efficace et cash ! Il y des choses pas faciles mais nécessaires à entendre. Je vais tacher de me réparer aussi vite que possible, suivant vos conseils</p>
                        <div className="reponsse">
                            <span className="title_res">Réponse de Florence :</span>
                            <p>Génial Marine bravo de prendre conscience de la situation. A très bientôt. Mercillili<br/>Florence</p>
                        </div>
                    </div>
                </div>
            </div>
            <footer>Copyright © 2023 | ENTREPRISE SAS - Tous droits reservés.</footer>
        </div>
    );
}

export default ProfilePage;