import { useState } from "react";
import ExpertBox from "../components/path/expertBox";

function NosExperts() {
    const [selected, setSelected] = useState(-1)

    return (
        <div id="nosExperts">
			<img src="/assets/logo.svg"/>
            
            <div className="container">
                <h2>Nos experts</h2>
                <div className="tags">
                    <span onClick={() => setSelected(-1)}>All</span>
                    <span onClick={() => setSelected(0)}>Test a</span>
                    <span onClick={() => setSelected(1)}>Test b</span>
                    <span onClick={() => setSelected(2)}>Test c</span>
                </div>
                <div className="blocks">
                    {
                        (selected === 0 || selected === -1) && <ExpertBox disponibily="dispo" />  
                    }
                    {
                        (selected === 1 || selected === -1) && <ExpertBox disponibily="8:00" />  
                    }
                    {
                        (selected === 2 || selected === -1) && <ExpertBox disponibily="horsLigne" />  
                    }
                    {
                        (selected === 2 || selected === -1) && <ExpertBox disponibily="10:00" />  
                    }
                </div>
            </div>
            <footer>Copyright © 2023 | ENTREPRISE SAS - Tous droits reservés.</footer>
        </div>
    );
}

export default NosExperts;