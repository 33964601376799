import { RiAccountCircleLine } from "react-icons/ri"
import { BsFillTelephoneFill } from "react-icons/bs"

function UserInfo() {
  return (
    <div className="user-info">
      <div className="info-contact">
        <div>
          <RiAccountCircleLine color="#81C313" className="userIcon" />   
          <h3>Yanis</h3>   
          <span></span>    
        </div>
        <div>
          <span className="idClient">1704</span>
          <span className="clientBadge">privé</span>
          <span>07:00 à 10:00</span>
        </div>
      </div>
      <div className="info-call">
        <div className="box">
          <BsFillTelephoneFill />
          <span>User 1</span>
        </div>
        <div className="box">
          00:25:00 min
        </div>
      </div>
    </div>
  );
}

export default UserInfo;