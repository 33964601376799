import MessageBox from "../../components/expert/messageBox";
import { LayoutExpert } from "../../components/layout/layout";
import NavigateBar from "../../components/tables/navigateBar";

function MyMessagesExpert() {
  return (
    <LayoutExpert id="myMessages">
      <h3>Mes messages</h3>
      
      <MessageBox />
      <MessageBox />
      <MessageBox />
      
      <NavigateBar />
    </LayoutExpert>
  );
}

export default MyMessagesExpert;