import Chart from "react-apexcharts";

function DataChart(props) {
  const state = {
    options: {
      colors: ["#772CF3"],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.8,
          opacityTo: 0.9,
          stops: [0, 100, 100]
        }
      },
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          }
        }
      },
      xaxis: {
        categories: ["Jan", "Fév", "Mars", "Avr", "Mai", "Juin", "Jui", "Août", "Sept", "Oct", "Nov", "Dec"]
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#772CF3']
        }
      },
      strocke: {
        curve: 'smooth'
      }
    },
    series: [
      {
        name: "series-1",
        data: [3800, 5000, 3800, 7000, 6500, 9500, 8000, 6000, 7500, 4000, 8000, 8500]
      }
    ]
  };

  return (
    <>
      <Chart 
        options={state.options}
        series={state.series}
        type="area"
        width="100%"
        height="300"
      />
    </>
  );
}

export default DataChart;