import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';


function ConfigpackageTable() {
  return (
    <>
      <div className='tableContainer'>
        <Table>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Association</Th>
              <Th>Nom</Th>
              <Th>Date de d'expiration</Th>
              <Th>Tarif</Th>
              <Th>Tarif barré</Th>
              <Th>Minutes</Th>
              <Th>Minutes offertes</Th>
              <Th>Couleur</Th>
              <Th>Nombre d'utilisations</Th>
              <Th>Nombre de subscription</Th>
              <Th>État</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>117</Td>
              <Td><span className='badge'>CBFo</span><span className='badge-green'>Callback</span></Td>
              <Td>Forfait Flash</Td>
              <Td>illimité</Td>
              <Td>20 €</Td>
              <Td> </Td>
              <Td>10</Td>
              <Td>N/A</Td>
              <Td><div className='color'></div></Td>
              <Td>illimité</Td>
              <Td>1</Td>
              <Td><span className='blue-cian'>Actif</span></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td>117</Td>
              <Td><span className='badge'>CBFo</span><span className='badge-green'>Callback</span></Td>
              <Td>Forfait Flash</Td>
              <Td>illimité</Td>
              <Td>20 €</Td>
              <Td> </Td>
              <Td>10</Td>
              <Td>N/A</Td>
              <Td><div className='color'></div></Td>
              <Td>illimité</Td>
              <Td>1</Td>
              <Td><span className='blue-cian'>Actif</span></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td>117</Td>
              <Td><span className='badge'>CBFo</span><span className='badge-green'>Callback</span></Td>
              <Td>Forfait Flash</Td>
              <Td>illimité</Td>
              <Td>20 €</Td>
              <Td> </Td>
              <Td>10</Td>
              <Td>N/A</Td>
              <Td><div className='color'></div></Td>
              <Td>illimité</Td>
              <Td>1</Td>
              <Td><span className='blue-cian'>Actif</span></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td>117</Td>
              <Td><span className='badge'>CBFo</span><span className='badge-green'>Callback</span></Td>
              <Td>Forfait Flash</Td>
              <Td>illimité</Td>
              <Td>20 €</Td>
              <Td> </Td>
              <Td>10</Td>
              <Td>N/A</Td>
              <Td><div className='color'></div></Td>
              <Td>illimité</Td>
              <Td>1</Td>
              <Td><span className='blue-cian'>Actif</span></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td>117</Td>
              <Td><span className='badge'>CBFo</span><span className='badge-green'>Callback</span></Td>
              <Td>Forfait Flash</Td>
              <Td>illimité</Td>
              <Td>20 €</Td>
              <Td> </Td>
              <Td>10</Td>
              <Td>N/A</Td>
              <Td><div className='color'></div></Td>
              <Td>illimité</Td>
              <Td>1</Td>
              <Td><span className='blue-cian'>Actif</span></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <NavigateBar />
    </>
  )
}

export default ConfigpackageTable;