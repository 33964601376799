import ConsultationBox from "../../components/expert/consultationBox";
import { LayoutExpert } from "../../components/layout/layout";
import NavigateBar from "../../components/tables/navigateBar";

function ConsultationExpert() {
  return (
    <LayoutExpert id="consultationExpert">
      <h3>Consultation </h3>

      <ConsultationBox
        title="DERNIERES CONSULTATION MANQUÉES" 
        data={{
          'ID': "000000",
          'Heure Appel': "12:03:56",
          'Expert': "Text",
          'Client': "Text",
          'Type': "Text",
          'Manqué par': "Text"
        }}
      />

      <ConsultationBox
        title="DERNIERES CONSULTATION" 
        data={{
          'ID': "000000",
          'Heure': "12:03:56",
          'Expert': "Text",
          'Client': "Text",
          'Durée': "0:19:59",
          'Type': "Text",
          'Manqué par': "Text"
        }}
      />

      <ConsultationBox
        title="DERNIERES CONSULTATION" 
        data={{
          'ID': "000000",
          'Date': "23/03/2023",
          'Heure': "12:03:56",
          'Client': "Text",
          'Expert': "Text"
        }}
      />

      <NavigateBar />
      <h4 className="text-center">Aucune donnée disponible</h4>
    </LayoutExpert>
  );
}

export default ConsultationExpert;