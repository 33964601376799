import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import ConfigpackageTable from "../../components/tables/configpackageTable";
import CustomSelect from "../../components/utils/CustomSelect";

function ConfigurationPackagePage() {
  return (
    <Layout id="configurationPackage">
      <div className="top-bar">
        <h2>Forfaits prives</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Experts</Link>
        </div>
      </div>

      <div className="filter-bar">
        <button className="nouveau">
            <span className="plus">+</span>
            <span className="text">Nouveau</span>
        </button>
        <div className="recherche">
          <input type="text" placeholder="Rechercher"/>
          <CustomSelect 
            options={[
              {value: 0, label: "Type"},
              {value: 1, label: "1"},
              {value: 2, label: "2"}
            ]}
          />
        </div>
      </div>

      <ConfigpackageTable />
    </Layout>
  );
}

export default ConfigurationPackagePage;
