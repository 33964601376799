import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "react-router-dom";
import ExpertsTable from "../../components/tables/expertsTable";
import CustomSelect from "../../components/utils/CustomSelect";

function ExpertPage() {
  return (
    <Layout id="expert">
      <div className="top-bar">
        <h2>Experts</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Experts</Link>
        </div>
      </div>
      <div className="recherche">
        <input type="text" placeholder="Rechercher"/>
        <CustomSelect 
          options={[
            {value: 0, label: "Type"},
            {value: 1, label: "1"},
            {value: 2, label: "2"}
          ]}
        />
      </div>
      <ExpertsTable />
    </Layout>
  );
}

export default ExpertPage;
