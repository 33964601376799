import ExpertBox from "../../components/client/expertBox";
import { LayoutClient } from "../../components/layout/layout";

function ExpertListClient() {
  return (
    <LayoutClient id="expertListClient">
      <h2>Liste d'experts</h2>
      
      <ExpertBox title="Expert 1" />
      <ExpertBox title="Expert 2" />
      <ExpertBox title="Expert 3" />
    </LayoutClient>
  );
}

export default ExpertListClient;