import { useState } from "react";
import Layout from "../../components/layout/layout";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Icon } from '@iconify/react';
import ModePlanning from "../../components/planning/modePlanning";
import ModeLecture from "../../components/planning/modeLecture";

function PlanningPage() {
  const [mode, setMode] = useState(0);
 
  return (
    <Layout id="planning">
      <div className="content-box">
        <div className="select-mode">
          <div className={`mode ${mode===0 ? "selected" : ""}`} onClick={() => setMode(0)}>
            <MdOutlineCalendarMonth />
            <span>Mode Planning</span>
          </div>
          <div className={`mode ${mode===1 ? "selected" : ""}`} onClick={() => setMode(1)}>
            <Icon icon="tabler:calendar-search" />
            <span>Mode Lecture</span>
          </div>
        </div>
        { mode === 0 ? (<ModePlanning />) : (<ModeLecture />)}
      </div>
    </Layout>
  );
}

export default PlanningPage;
