import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import './styles.scss';

// pages
import Home from './pages/admin/homePage';
import Consultation from './pages/admin/consultationPage';
import Planning from './pages/admin/planningPage';
import Expert from './pages/admin/expertPage';
import AnalyseExpert from './pages/admin/analyseExpertPage';
import ClientList from './pages/admin/clientListPage';
import AnalyseClient from './pages/admin/analyseClientPage';
import PromoCode from './pages/admin/promoCodePage';
import Advice from './pages/admin/advicePage';
import PurchasePackage from './pages/admin/purchasePackagePage';
import ConfigurationPackage from './pages/admin/configurationPackagePage';
import CabinetConfiguration from './pages/admin/cabinetConfigurationPage';
import AdminAccount from './pages/admin/adminAccountPage';
// expert
import HomePageExpert from './pages/expert/homePage'; 
import MyMessagesExpert from './pages/expert/myMessages';
import ConsultationExpert from './pages/expert/consultation';
import MailsExpert from './pages/expert/mails';
import AdviceExpert from './pages/expert/advice';
import ProfileExpert from './pages/expert/profile';
import MyPlanning from './pages/expert/myPlanning';
import Conversation from './pages/expert/conversation';
import StatistiqueExpert from './pages/expert/statistique';
// client
import ProfileClient from './pages/client/profile';
import ExpertListClient from './pages/client/expertList';
import MyConsultationsClient from './pages/client/myConsultations';
import MyPurchaseClient from './pages/client/myPurchase';
import PayementClient from './pages/client/payement';
import MyMeetingClient from './pages/client/myMeeting';
import MyMethodsPayementClient from './pages/client/myMethodsPayement';
// path
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import PasswordReset from './pages/password-reset';
import ForfaitsPage from './pages/forfaits';
import NosExperts from './pages/nosExpert';
import ProfilePage from './pages/profile';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path='/admin/' Component={Home} />
        <Route path='/admin/consultation' Component={Consultation} />
        <Route path='/admin/planning' Component={Planning} />
        <Route path='/admin/experts' Component={Expert} />
        <Route path='/admin/analyseExpert' Component={AnalyseExpert} />
        <Route path='/admin/clientList' Component={ClientList} />
        <Route path='/admin/analyseClient' Component={AnalyseClient} />
        <Route path='/admin/promoCode' Component={PromoCode} />
        <Route path='/admin/advice' Component={Advice} />
        <Route path='/admin/purchasePackage' Component={PurchasePackage} />
        <Route path='/admin/configurationPackage' Component={ConfigurationPackage} />
        <Route path='/admin/cabinetConfiguration' Component={CabinetConfiguration} />
        <Route path='/admin/adminAccount' Component={AdminAccount} />

        <Route exact path='/expert/' Component={HomePageExpert} />
        <Route path='/expert/mymessages' Component={MyMessagesExpert} />
        <Route path='/expert/consultation' Component={ConsultationExpert} />
        <Route path='/expert/mails' Component={MailsExpert} />
        <Route path='/expert/advice' Component={AdviceExpert} />
        <Route path='/expert/profile' Component={ProfileExpert} />
        <Route path='/expert/planning' Component={MyPlanning} />
        <Route path='/expert/conversation' Component={Conversation} />
        <Route path='/expert/statistique' Component={StatistiqueExpert} />

        <Route exact path='/client/' Component={ProfileClient} />
        <Route path='/client/expertList' Component={ExpertListClient} />
        <Route path='/client/myconsultations' Component={MyConsultationsClient} />
        <Route path='/client/mypurchase' Component={MyPurchaseClient} />
        <Route path='/client/payement' Component={PayementClient} />
        <Route path='/client/mymeeting' Component={MyMeetingClient} />
        <Route path='/client/mymethodspayement' Component={MyMethodsPayementClient} />

        <Route path='/login' Component={LoginPage} />
        <Route path='/register' Component={RegisterPage} />
        <Route path='/password' Component={PasswordReset} />
        <Route path='/forfaits' Component={ForfaitsPage} />
        <Route path='/experts' Component={NosExperts} />
        <Route exact path='/' Component={ProfilePage} />
        <Route path='/profile' Component={ProfilePage} />
      </Routes>
    </Router>
  </React.StrictMode>
);