import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function CardTable() {
  return (
    <div className='tableContainer'>
      <Table>
        <Thead>
          <Tr>
            <Th>CARTE</Th>
            <Th>DATE DE SAISIE</Th>
            <Th>MARQUE</Th>
            <Th>ACTION</Th>
          </Tr>
        </Thead>
      </Table>
    </div>
  );
}

export default CardTable;