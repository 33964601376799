import { Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io";
import { BiDotsHorizontalRounded } from "react-icons/bi";

function CabinetConfigurationPage() {
  return (
    <Layout id="cabinetConfiguration">
      <div className="top-bar">
        <h2>Cabinet Configuration</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Experts</Link>
        </div>
      </div>

      <div className="content-box">
        <h2>Personnalisation du cabinet</h2>
        <button className="btn-light">Nouvelle configuration</button>
        <label htmlFor="cabinetName">Nom cabinet</label>
        <input name="cabinetName" defaultValue="Mon Cabinet" className="input-light" />
        <label htmlFor="logoInput">Logo du cabinet (max. 200x200)</label>
        <div className="btn-group">
          <button className="secondary">Modifier</button>
          <button className="primary">Supprimer</button>
        </div>
        <img src="/assets/logo.svg" className="logo" alt="logo" />
        <div className="input-file">
          <input type="file" id="file" accept="image/*" onChange={(e) => e} title=" " />
          <label className="text">Sélection votre logo</label>
          <label htmlFor="file" className="button"><BiDotsHorizontalRounded color="#B0B0B0" /></label>
        </div>
      </div>
    </Layout>
  );
}

export default CabinetConfigurationPage;
