import { Icon } from "@iconify/react";
import { useState } from "react";


function DragDrop() {
  const [image, setImage] = useState(null)

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  }

  const handleDrop = (e) => {
    // Note: preventDefault is on nativeEvent
    e.nativeEvent.preventDefault()
    if (!e) return;
    // Note: files are under nativeEvent.dataTransfer
    const files = e.nativeEvent.dataTransfer.files;
    const fileList = Array.from(files);
    setImage(URL.createObjectURL(fileList[0]));
  }

  return (
    <>
      <input type="file" onChange={onImageChange} accept="image/jpeg, image/png, image/jpg" name="form" id="form" />
      <label htmlFor="form" className="drag-drop" onDragOver={(e) => {e.preventDefault()}} onDrop={handleDrop}>
        {
          image ? (<img className="image" src={image} />) : (<Icon icon="material-symbols:photo-library-rounded" />)
        }
        <span>Ajouter une photo</span>
      </label>
    </>
  );
}

export default DragDrop;