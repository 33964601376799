import { Icon } from '@iconify/react';
import CustomSelect from '../utils/CustomSelect';

function ModePlanning() {
  return (
    <>
    
      <div className="modePlanning">
        <div className="choiceBox">
          <div className="radioInputs">
            <div>
              <input type="radio" id="planningRadio" name="radio" value="Mon Planning" checked />
              <label htmlFor="planningRadio">Mon Planning</label>
            </div>
            <div>
              <input type="radio" id="tranchesRadio" name="radio" value="Tranches disponibles" />
              <label htmlFor="tranchesRadio">Tranches disponibles</label>
            </div>
          </div>
          <div className="select">
            <CustomSelect 
              options={[{value: 0, label: "Mon Cabinet"}]}
            />
          </div>
          <div className="select">
            <CustomSelect 
              options={[{value: 0, label: "Mon Cabinet"}]}
            />
          </div>
        </div>
      </div>

      <div className="dateBar">
        <div className='navigation'>
          <button className="btnGreen-arrow"><Icon icon="material-symbols:chevron-left" /></button>
          <button className="btnGreen-arrow"><Icon icon="material-symbols:chevron-right" /></button>
        </div>
        <div>
          <button className='btnGreen large'>Aujourd'hui</button>
        </div>
        <h4>6 - 12 MARS 2023</h4>
        <div>
          <button className="btnGreen">Mois</button>
          <button className="btnGreen">Semaine</button>
          <button className="btnGreen">Jour</button>
          <button className="btnGreen">Liste</button> 
        </div>
      </div>

      <div className='content'>
        <h5>Experts</h5>
        <div className='emplois'>
          <div className='badges'>
            <span className='badge'>Yanis</span>
            <span className='badge blue'>Test</span>
            <span className='badge red'>Test 2</span>
            <span className='badge orange'>Test 3</span>
            <span className='badge yellow'>Test 4</span>
          </div>
          <div className='emploi'>
            <div className='part'>
              <div className='top'>00 h</div>
              <div>
                <div className='top'>lun 06/03</div>
                <div></div>
              </div>
              <div>
                <div className='top'>mar 07/03</div>
                <div></div>
              </div>
              <div>
                <div className='top'>mer 08/03</div>
                <div className=''></div>
              </div>
              <div>
                <div className='top selected'>jeu 09/03</div>
                <div className=''>
                  <span className='expert-badge'>Yannis</span>
                  <span className='expert-badge blue'>Test</span>
                  <span className='expert-badge red'>Test 2</span>
                  <span className='expert-badge orange'>Test 3</span>
                  <span className='expert-badge yellow'>Test 4</span>
                </div>
              </div>
              <div>
                <div className='top'>ven 10/03</div>
                <div></div>
              </div>
              <div>
                <div className='top'>sam 11/03</div>
                <div></div>
              </div>
              <div>
                <div className='top'>dim 12/03</div>
                <div></div>
              </div>
            </div>
            {
              ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"].map((elem) => {
                return (
                  <div className='part'>
                    <div className='top'>{elem} h</div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                    <div>
                      <div></div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default ModePlanning;