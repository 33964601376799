import { LayoutClient } from "../../components/layout/layout";
import PurchaseTable from "../../components/tables/purchaseTable";

function MyPurchaseClient() {
  return (
    <LayoutClient id="myPurchaseClient">
      <h2>Mes achats</h2>

      <h3>Forfaits</h3>
      <PurchaseTable />

      <h3>Randez-Vous</h3>
      <PurchaseTable />

    </LayoutClient>
  );
}

export default MyPurchaseClient;