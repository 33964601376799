function ModeLecture() {
  return (
    <div className="w-100">
      <div className="modeLecture">
        <hr/>
        <h3>06 h - 07 h</h3>
        <div className='table'>
          <div className="ligne">
            <div><span className="text-green">07 h - 08 h</span></div>
            <div><span className="text-purple">1</span></div>
            <div className="names"><span>Althèa</span></div>
          </div>
          <div className="ligne">
            <div><span className="text-green">08 h - 09 h</span></div>
            <div><span className="text-purple">3</span></div>
            <div className="names">
              <span>Johana Cerise</span>
              <span>Manon</span>
              <span>Althèa</span>
            </div>
          </div>
          <div className="ligne selected">
            <div><span className="text-green">09 h - 10 h</span></div>
            <div><span className="text-purple">5</span></div>
            <div className="names">
              <span>Johana Cerise</span>
              <span>Indiana</span>
              <span>Manon</span>
              <span>Althèa</span>
              <span>Florence</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModeLecture;