import { Icon } from "@iconify/react";
import { LayoutClient } from "../../components/layout/layout";
import CardTable from "../../components/tables/cardTable";

function MyMethodsPayementClient() {
  return (
    <LayoutClient id="myMethodsPayementClient">
      <h2></h2>

      <CardTable />
      <div className="btn-group">
        <button>Ajouter une carte</button>
      </div>

      <div className="box">
        <span className="title">NOUVELLE CARTE</span>
        
        <form>
          <div className="form-group">
            <label>Numéro de carte</label>
            <div class="input-container">
              <div className="icon">
                <Icon icon="ic:baseline-payment" color="#8AC923" />
              </div>
              <input class="input-field" type="text" name="nbCard" />
            </div>
          </div>
          <div className="col-2">
            <div className="form-group">
              <label>Date d’expiration</label>
              <div class="input-container">
                <div className="icon">
                  <Icon icon="material-symbols:calendar-month-sharp" color="#8AC923" />
                </div>
                <input class="input-field" type="text" name="nbCard" />
              </div>
            </div>
            <div className="form-group">
              <label>CVC</label>
              <div class="input-container">
                <div className="icon">
                  <Icon icon="material-symbols:lock" color="#8AC923" />
                </div>
                <input class="input-field" type="text" name="cvc" />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label>Titulaire (Prénom, Nom inscrit sur la carte)</label>
            <div class="input-container">
              <div className="icon">
                <Icon icon="mdi:account" color="#8AC923" />
              </div>
              <input class="input-field" type="text" name="Titulaire" />
            </div>
          </div>

        </form>
        <div className="col-flex">
          <Icon icon="logos:visaelectron" />
          <Icon icon="logos:mastercard" />
        </div>
        <div className="col-flex">
          <Icon icon="material-symbols:lock" color="#F5CE00" />
          <span>Paiement sécurisé</span>
        </div>
      
        <button className="btn">Valider</button>
      </div>
    </LayoutClient>
  );
}

export default MyMethodsPayementClient;