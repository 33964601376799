import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';
import { Icon } from '@iconify/react';


function AnalyseexpertTable() {
  return (
    <>
      <div className='tableContainer'>
        <table>
          <thead>
            <colgroup span="1"></colgroup>
            <tr>
              <td rowSpan="1"></td>
              <td colSpan="4" scope="colgroup" className='head'>Privé</td>
            </tr>
          </thead>
          <tbody>
            <tr className='second-head'>
              <th scope="col" className='br'><div className='sb'>Expert<Icon icon="ph:arrows-down-up-thin" color='#A7A7A7' /></div></th>
              <th scope="col"><Icon icon="bi:telephone-inbound" /></th>
              <th scope="col"><Icon icon="ph:arrows-down-up-thin" color='#A7A7A7' /><Icon icon="uiw:time-o" /></th>
              <th scope="col"><Icon icon="ph:arrows-down-up-thin" color='#A7A7A7' /><Icon icon="material-symbols:euro" /></th>
              <th scope="col"><Icon icon="ph:arrows-down-up-thin" color='#A7A7A7' style={{"float": "right"}} /></th>
            </tr>
            <tr>
              <th scope="row" className='br'><div className='sb'>Lou</div></th>
              <td>0</td>
              <td>00:00</td>
              <td>0 €</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row" className='br'><div className='sb'>Yam</div></th>
              <td>0</td>
              <td>00:00</td>
              <td>0 €</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row" className='br'><div className='sb'>Lou</div></th>
              <td>0</td>
              <td>00:00</td>
              <td>0 €</td>
              <td></td>
            </tr>
            <tr>
              <th scope="row" className='br'><div className='sb'>Yam</div></th>
              <td>0</td>
              <td>00:00</td>
              <td>0 €</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <NavigateBar />
    </>
  )
}

export default AnalyseexpertTable;