function BoxTd() {
  return (
    <div className="box box-td">
      <h3>Taux décroché</h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">Ce mois-ci</span>

          <span className="primary-text mb-3">91 %</span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text">93 %</span>
        </div>
        <div>
          <span className="sub-title-gray">Aujourd’hui</span>

          <span className="primary-text mb-3">94 %</span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text">95 %</span>
        </div>
      </div>
    </div>
  )
}

export default BoxTd