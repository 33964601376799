import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';


function PromocodeTable() {
  return (
    <>
      <div className='tableContainer'>
        <Table>
          <Thead>
            <Tr>
              <Th>Nom du code promo</Th>
              <Th>État</Th>
              <Th>Utilisation</Th>
              <Th>Type</Th>
              <Th>Mode de<br/>consultation</Th>
              <Th>Crée le</Th>
              <Th>Date de<br/>début de validité</Th>
              <Th>Date de fin<br/>de validité</Th>
              <Th>Valeur / Prix</Th>
              <Th>Nb Minutes</Th>
              <Th>Nombre d'utilisations</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td className="align-left">5FREE</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>Inscription</Td>
              <Td>Minutes</Td>
              <Td>Forfait Prive</Td>
              <Td>23/02/2023<br/>20:02</Td>
              <Td>23/02/2023<br/>00:00</Td>
              <Td>23/11/2023<br/>23:59</Td>
              <Td>0</Td>
              <Td>5</Td>
              <Td>28</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td className="align-left">5FREE</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>Inscription</Td>
              <Td>Minutes</Td>
              <Td>Forfait Prive</Td>
              <Td>23/02/2023<br/>20:02</Td>
              <Td>23/02/2023<br/>00:00</Td>
              <Td>23/11/2023<br/>23:59</Td>
              <Td>0</Td>
              <Td>5</Td>
              <Td>28</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td className="align-left">5FREE</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>Inscription</Td>
              <Td>Minutes</Td>
              <Td>Forfait Prive</Td>
              <Td>23/02/2023<br/>20:02</Td>
              <Td>23/02/2023<br/>00:00</Td>
              <Td>23/11/2023<br/>23:59</Td>
              <Td>0</Td>
              <Td>5</Td>
              <Td>28</Td>
              <Td></Td>
            </Tr>
            <Tr>
              <Td className="align-left">5FREE</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>Inscription</Td>
              <Td>Minutes</Td>
              <Td>Forfait Prive</Td>
              <Td>23/02/2023<br/>20:02</Td>
              <Td>23/02/2023<br/>00:00</Td>
              <Td>23/11/2023<br/>23:59</Td>
              <Td>0</Td>
              <Td>5</Td>
              <Td>28</Td>
              <Td></Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <NavigateBar />
    </>
  )
}

export default PromocodeTable;