import ForfaitBox from "../components/path/forfaitBox";

function ForfaitsPage() {
  return (
		<div id="forfaitsPath">
			<img src="/assets/logo.svg"/>
			<div className="container">
				<h2>Paquets</h2>
				<div className="blocks">
					<ForfaitBox 
						price="20"
						time="10"
					/>
					<ForfaitBox 
						price="37"
						time="20"
					/>
					<ForfaitBox 
						price="52.5"
						time="30"
					/>
					<ForfaitBox 
						price="70"
						time="40"
					/>
				</div>
			</div>
			<footer>Copyright © 2023 | ENTREPRISE SAS - Tous droits reservés.</footer>
		</div>
	);
}

export default ForfaitsPage;