import BoxCa from "../../components/home/boxCa";
import BoxConsultations from "../../components/home/boxConsultations";
import BoxTd from "../../components/home/boxTd";
import Layout from "../../components/layout/layout";
import DataChart from "../../components/utils/chart";
import NotifBlock from "../../components/utils/notifBlock";

function HomePage() {
  return (
    <Layout id="home">
      <h2>Mon tableau de bord</h2>
      <div className="grid">
        <BoxCa />
        <BoxConsultations />
        <BoxTd />
        <div className="box box-eca">
          <h3>Évolution du chiffre d'affaires</h3>
          <DataChart />
        </div>
        <div className="box box-ar">
          <h3>Activité récente</h3>
          <div className="blocks">
            <NotifBlock 
              type="success"
              title="Nouveau client"
              subTitle="Yanis vient de s’inscrire sur votre cabinet."
              date="8 mars 2023 | 18h38"
            />
            <NotifBlock
              type="info"
              title="Statut réinitialisé"
              subTitle="Yanis Two vient réinitialisé son statut."
              date="8 mars 2023 | 18h38"
            />
            <NotifBlock 
              type="success"
              title="Nouveau client"
              subTitle="Yanis vient de s’inscrire sur votre cabinet."
              date="8 mars 2023 | 18h38"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HomePage;
