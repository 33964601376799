function PasswordReset() {
    return (
        <div id="passwordReset">
            <div className="content">
				<img src="/assets/logo.svg"/>

				<form>
					<div className="form-group">
						<label>E-mail</label>
						<input placeholder="Entrer votre email" type="email"/>
					</div>
					<input type="submit" value="Demander un nouveau mot de passe" className="submit"/>
				</form>
			</div>
			<div className="bg">
				<img src="/assets/logo.svg"/>
			</div>
        </div>
    );
}

export default PasswordReset;