import MailBox from "../../components/expert/mailBox";
import { LayoutExpert } from "../../components/layout/layout";
import NavigateBar from "../../components/tables/navigateBar";

function MailsExpert() {
  return (
    <LayoutExpert id="mails">
      <h2>Mes Mails</h2>

      <MailBox 
        userName='User 1'
        date='4hr ago'
        title='Le titre du courriel'
        text='Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text.'
      />
      <MailBox 
        userName='User 1'
        date='4hr ago'
        title='Le titre du courriel'
        text='Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text.'
      />
      <MailBox 
        userName='User 1'
        date='4hr ago'
        title='Le titre du courriel'
        text='Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text.'
      />

      <NavigateBar />
    </LayoutExpert>
  );
}

export default MailsExpert;