import AdviceBox from "../../components/expert/adviceBox";
import { LayoutExpert } from "../../components/layout/layout";

function AdviceExpert() {
  return (
    <LayoutExpert id="adviceExpert">
      <h2>Mes Avis</h2>

      <AdviceBox 
        id="#135035"
        title="Test  (Publié) - (Réponse publiée)"
        alert="Lorem ipsum dolor sit amet, consecteur adipiscing elit. Nam et porttitor justo. Integer id vestibulum orci, a ultrices mi. Mauris ultricies, mauris malesuada aliquet tempor, nulla enim faucibus velit, eu euismod diam lorem sed nibh."
      >
        Par client<br/>
        Lorem ipsum dolor sit amet, consecteur adipiscing elit. Nam et porttitor justo. Integer id vestibulum orci, a ultrices mi. Mauris ultricies, mauris malesuada aliquet tempor, nulla enim faucibus velit, eu euismod diam lorem sed nibh.
      </AdviceBox>
      <AdviceBox
        id="#135035"
        title="Test  (Publié) - (Réponse publiée)"
      >
        Par client<br/>
        Lorem ipsum dolor sit amet, consecteur adipiscing elit. Nam et porttitor justo. Integer id vestibulum orci, a ultrices mi. Mauris ultricies, mauris malesuada aliquet tempor, nulla enim faucibus velit, eu euismod diam lorem sed nibh.
      </AdviceBox>
    </LayoutExpert>
  );
}

export default AdviceExpert;