import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';


function AdminaccTable() {
  return (
    <>
      <div className='tableContainer'>
        <Table>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Nom</Th>
              <Th>E-mail</Th>
              <Th>Téléphone</Th>
              <Th>Date de création</Th>
              <Th>Admin</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td className="align-left">421</Td>
              <Td>Yanis Test</Td>
              <Td>contact@test.com</Td>
              <Td></Td>
              <Td>05/04/2022</Td>
              <Td><div className='type'>Standard</div></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td className="align-left">421</Td>
              <Td>Yanis Test</Td>
              <Td>contact@test.com</Td>
              <Td></Td>
              <Td>05/04/2022</Td>
              <Td><div className='type'>Standard</div></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td className="align-left">421</Td>
              <Td>Yanis Test</Td>
              <Td>contact@test.com</Td>
              <Td></Td>
              <Td>05/04/2022</Td>
              <Td><div className='type'>Standard</div></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
            <Tr>
              <Td className="align-left">421</Td>
              <Td>Yanis Test</Td>
              <Td>contact@test.com</Td>
              <Td></Td>
              <Td>05/04/2022</Td>
              <Td><div className='type'>Standard</div></Td>
              <Td><span className='badge'>Modifier</span></Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <NavigateBar />
    </>
  )
}

export default AdminaccTable;