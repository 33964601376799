import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';


function AnalyseclientTable() {
  return (
    <>
      <div className='tableContainer'>
        <Table>
          <Thead>
            <Tr>
              <Th>Rang</Th>
              <Th>Client</Th>
              <Th>Téléphine</Th>
              <Th>E-mail</Th>
              <Th>Date<br/>inscription</Th>
              <Th>Première consultation</Th>
              <Th>Dernière consultation</Th>
              <Th>Durée</Th>
              <Th>Montant</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>1</Td>
              <Td>Yanis</Td>
              <Td>0000000000</Td>
              <Td className="color-green">test@gmail.com</Td>
              <Td>11/06/2021</Td>
              <Td>11/06/2021<br/>18:00</Td>
              <Td>08/03/2023<br/>21:04</Td>
              <Td>18:29:53</Td>
              <Td>141.5</Td>
            </Tr>
            <Tr>
              <Td>1</Td>
              <Td>Yanis</Td>
              <Td>0000000000</Td>
              <Td className="color-green">test@gmail.com</Td>
              <Td>11/06/2021</Td>
              <Td>11/06/2021<br/>18:00</Td>
              <Td>08/03/2023<br/>21:04</Td>
              <Td>18:29:53</Td>
              <Td>141.5</Td>
            </Tr>
            <Tr>
              <Td>1</Td>
              <Td>Yanis</Td>
              <Td>0000000000</Td>
              <Td className="color-green">test@gmail.com</Td>
              <Td>11/06/2021</Td>
              <Td>11/06/2021<br/>18:00</Td>
              <Td>08/03/2023<br/>21:04</Td>
              <Td>18:29:53</Td>
              <Td>141.5</Td>
            </Tr>
            <Tr>
              <Td>1</Td>
              <Td>Yanis</Td>
              <Td>0000000000</Td>
              <Td className="color-green">test@gmail.com</Td>
              <Td>11/06/2021</Td>
              <Td>11/06/2021<br/>18:00</Td>
              <Td>08/03/2023<br/>21:04</Td>
              <Td>18:29:53</Td>
              <Td>141.5</Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <NavigateBar />
    </>
  )
}

export default AnalyseclientTable;