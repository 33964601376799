import { useState } from "react";
import DragDrop from "../../components/expert/drag&drop";
import { LayoutExpert } from "../../components/layout/layout";
import CustomSelect from "../../components/utils/CustomSelect";


function ProfileExpert() {
  const [image, setImage] = useState(null)
  const [tags, setTags] = useState(['RH', 'Gestion'])

  const handleChangeTag = (e) => {
    setTags([...tags, e]);
  }

  const handleDeleteTag = (element) => {
    let _tags = [...tags]

    _tags.splice(element, 1)
    setTags(_tags)
  }


  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  }
  
  return (
    <LayoutExpert id="profileExpert">
      <div className="grey-part" style={{"background": image ? `url(${image})` : ''}}>
        <label className="top-bar" htmlFor="modifyBack">
          <h2>Mon profil</h2>
          <span>Modifier</span>
        </label>
        <input type="file" onChange={onImageChange} accept="image/jpeg, image/png, image/jpg" name="modifyBack" id="modifyBack" />
        <DragDrop />
      </div>
      <div className="container">
        <span className="name">Name</span>
        <span className="headline">Add headline</span>

        <form>
          <div className="form-group">
            <label>Nom professionnel</label>
            <input placeholder="Test test" />
          </div>
          <div className="form-group">
            <label>Specialites</label>
            <input placeholder="Comptable" />
          </div>
          <div className="form-group">
            <label>E-mail</label>
            <input type="email" />
          </div>
          <div className="form-group">
            <label>Categories</label>
            <div className="input-tags">
              {
                Object.keys(tags).map((tag) => (
                  <div className="tag" key={tag} onClick={() => handleDeleteTag(parseInt(tag))}>
                    {tags[tag]}
                  </div>
                ))
              }
              <CustomSelect 
                options={[
                  {value: "RH", label: "RH"},
                  {value: "GESTION", label: "GESTION"}
                ]}
                onChange={handleChangeTag}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Téléphone</label>
            <input placeholder="000000000000" />
          </div>
        </form>
      </div>
    </LayoutExpert>
  );
}

export default ProfileExpert; 