import { Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io";
import UserInfo from "../../components/consultation/userInfo";
import ConsultationDropDown from "../../components/consultation/consultationDropDown";

function ConsultationPage() {
  return (
    <Layout id="consultation">
      <div className="top-bar">
        <h2>Consultations en cours</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Consultations en cours</Link>
        </div>
      </div>
      <div className="grid">
        <UserInfo />
        <UserInfo />
        <ConsultationDropDown title="Experts en consultation" />
        <ConsultationDropDown title="Experts disponible" />
      </div>
    </Layout>
  );
}

export default ConsultationPage;
