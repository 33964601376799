import { Icon } from '@iconify/react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function StatistiqueTable() {
  return (
    <div className="tableContainer">
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>Date</Th>
            <Th>Heure</Th>
            <Th>Durée</Th>
            <Th>Durée<br/>Max</Th>
            <Th>Code<br/>Promo</Th>
            <Th>Client</Th>
            <Th>Gain</Th>
            <Th>Reversement</Th>
            <Th>Prestation</Th>
            <Th>Etat</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td className="color-green">1</Td>
            <Td className="color-red">13/03/2023</Td>
            <Td>12:03:56</Td>
            <Td>0:19:59</Td>
            <Td>0:20:03</Td>
            <Td>Aucun</Td>
            <Td>Manon</Td>
            <Td className="color-green">9.99</Td>
            <Td><span className='badge'>CBFo</span><br/><br/><span className='badge-green'>Callback</span></Td>
            <Td>10 $ HT/heure</Td>
            <Td>traité</Td>
            <Td><Icon icon="mdi:account-check-outline" color='#772CF3'/></Td>
          </Tr>
          <Tr>
            <Td className="color-green">1</Td>
            <Td className="color-red">13/03/2023</Td>
            <Td>12:03:56</Td>
            <Td>0:19:59</Td>
            <Td>0:20:03</Td>
            <Td>Aucun</Td>
            <Td>Manon</Td>
            <Td className="color-green">9.99</Td>
            <Td><span className='badge'>CBFo</span><br/><br/><span className='badge-green'>Callback</span></Td>
            <Td>10 $ HT/heure</Td>
            <Td>traité</Td>
            <Td><Icon icon="mdi:account-check-outline" color='#772CF3'/></Td>
          </Tr>
          <Tr>
            <Td className="color-green">1</Td>
            <Td className="color-red">13/03/2023</Td>
            <Td>12:03:56</Td>
            <Td>0:19:59</Td>
            <Td>0:20:03</Td>
            <Td>Aucun</Td>
            <Td>Manon</Td>
            <Td className="color-green">9.99</Td>
            <Td><span className='badge'>CBFo</span><br/><br/><span className='badge-green'>Callback</span></Td>
            <Td>10 $ HT/heure</Td>
            <Td>traité</Td>
            <Td><Icon icon="mdi:account-check-outline" color='#772CF3'/></Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default StatistiqueTable;