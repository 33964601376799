import { HiOutlineInformationCircle } from 'react-icons/hi';

function NotifBlock(props) {
  return (
    <div className={`notifblock ${props.type}`}>
      <span className="title"><HiOutlineInformationCircle />{props.title}</span>
      <span className="sub-title">{props.subTitle}</span>
      <span className="date">{props.date}</span>
    </div>
  );
}

export default NotifBlock;