import { Icon } from "@iconify/react";
import { LayoutClient } from "../../components/layout/layout";
import CustomSelect from "../../components/utils/CustomSelect";

function PayementClient() {
  return (
    <LayoutClient id="payementClient">
      <h2>Créditer mon compte</h2>

      <div className="form-control">
        <label>Je choisis mon forfait privé</label>
        <CustomSelect 
          options={[
            {value: 0, label: "Fofait Test (2$ pour 10 minutes)"}
          ]}
        />
      </div>

      <div className="split-line">
        <span>Forfait Test (2$ pour 10 minutes)</span>
        <span>2</span>
      </div>

      <hr style={{"marginTop": "27px"}} />

      <div className="form-control">
        <label>Code Promo</label>
        <div className="input-control">
          <div className="icon">
            <Icon icon="ic:outline-label" color="#000000" />
          </div>
          <input />
          <button>OK</button>
        </div>
      </div>

      <hr style={{"marginTop": "40px"}} />

      <div className="split-line">
        <span>Total</span>
        <span>2 $ TTC</span>
      </div>

      <div className="box selected">
        <div className="form">
          <input type="radio" value="2 $ TTC" id="new-card" />
          <label htmlFor="new-card">Nouvelle carte</label>
        </div>
      </div>

      <div className="box">
        <form>
          <div className="form-control">
            <label>Numéro de carte</label>
            <input />
          </div>
          <div className="col-2">
            <div className="form-control">
              <label>Date d’expiration</label>
              <input />
            </div>
            <div className="form-control">
              <label>CVC</label>
              <input />
            </div>
          </div>
          <div className="form-control">
            <label>Titulaire (Prénom, Nom inscrit sur la carte)</label>
            <input />
          </div>
          <div className="check-form">
            <input type="checkbox" />
            <label>Sauvegarder la carte?</label>
          </div>
          <div className="col-2-button">
            <button>Valider</button>
            <div className="col-flex">
              <Icon icon="material-symbols:lock" color="#F5CE00" />
              <span>Paiement sécurisé</span>
            </div>
          </div>
        </form>
      </div>
    </LayoutClient>
  );
}

export default PayementClient;