import { Icon } from '@iconify/react';

function NavigateBar() {
  return (
    <div className='control-navigate'>
      <span>1-8 of 8</span>
      <Icon icon="streamline:entertainment-control-button-previous-button-television-buttons-movies-skip-previous-video-controls" />
      <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
      <Icon icon="material-symbols:arrow-forward-ios-rounded" />
      <Icon icon="streamline:entertainment-control-button-next-button-television-buttons-movies-skip-next-video-controls" />
    </div>
  )
}

export default NavigateBar;