import { AiFillStar } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";

function ExpertInfoBox() {
  return (
    <div className="expert-info-box">
      <div>
        <HiOutlineUserCircle color="#3EA336A0"  className="user-logo" />
      </div>
      <div className="user-info">
        <span className='name'>Test Test</span>
        <span className='category'>Expert, Gestion</span>
        <div className="review">
          <div className='stars'>
            <AiFillStar color="#E7CE4C"/>
            <AiFillStar color="#E7CE4C"/>
            <AiFillStar color="#E7CE4C"/>
            <AiFillStar color="#E7CE4C"/>
            <AiFillStar color="#E7CE4C"/>
          </div>
          <span className='note'>4.9</span>
        </div>
        <span className='nb-consultation'>13558 consultation(s) depuis le début</span>
      </div>
    </div>
  );
}

export default ExpertInfoBox;