import { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneNumberInput from '../components/inputPhone/PhoneNumberInput';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function RegisterPage() {
	const [startDate, setStartDate] = useState(new Date());

	return (
		<div id="registerPage">
			<div className="content">
				<img src="/assets/logo.svg" className='logo'/>
				<h2>Content de te revoir!</h2>
				<span className="sub-title">Entrez vos données</span>

				<form>
					<div className="form-group">
						<label>Nom et prénom</label>
						<input placeholder="VOTRE NOM ET PRENOM *" type="text"/>
					</div>
					<div className="form-group">
						<label>E-mail</label>
						<input placeholder="VOTRE E-MAIL *" type="email"/>
					</div>
					<div className="form-group">
						<label>Numéro de télephone.</label>
						<PhoneNumberInput />
					</div>
					<div className="form-group">
						<label>Date de naissance</label>
						<DatePicker className="input-date-naissance" selected={startDate} onChange={(date) => setStartDate(date)} />
					</div>
                    <div className="form-group">
						<input placeholder="MOT DE PASSE (8 caracteres minimum) *" type="password"/>
					</div>
                    <div className="form-group">
						<input placeholder="CONFIRMATION MOT DE PASSE *" type="password"/>
					</div>
                    <div className="checkbox">
                        <input type="checkbox" id="reciveOffer"/><label htmlFor="reciveOffer">Je souhaite recevoir des offres exclusives</label>
                    </div>
                    <div className="checkbox">
                        <input type="checkbox" id="ihave18"/><label htmlFor="ihave18">J’ai plus de 18 ans, j’ai lu et j’approuve les<br/>conditions generales d’utilisation</label>
                    </div>
					<input type="submit" value="VALIDER" className="submit"/>
				</form>
			</div>
			<div className="bg">
				<img src="/assets/logo.svg"/>
			</div>
		</div>
  );
}

export default RegisterPage;