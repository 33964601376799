import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';
import { Icon } from '@iconify/react';
import CustomSelect from '../utils/CustomSelect';


function ClientlistTable() {
  return (
    <>
      <div className='tableContainer'>
        <Table>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Pseudo</Th>
              <Th>Client</Th>
              <Th>Email</Th>
              <Th>Téléphone</Th>
              <Th>Inscription</Th>
              <Th>Balance privée</Th>
              <Th>Actif</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td><span className='color-green'><Icon icon="fluent-mdl2:circle-addition" color='#81C313' />11<br/>1062</span></Td>
              <Td>Yanis<br/><span className='badge'>949129</span></Td>
              <Td>Yanis</Td>
              <Td><span className='color-green'>test@gmail.com</span></Td>
              <Td>0000000000</Td>
              <Td>11/06/2021</Td>
              <Td>32 m 59 s</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>
                <CustomSelect 
                  options={[{value: 0, label: "Action"}]}
                />
              </Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'><Icon icon="fluent-mdl2:circle-addition" color='#81C313' />11<br/>1062</span></Td>
              <Td>Yanis<br/><span className='badge'>949129</span></Td>
              <Td>Yanis</Td>
              <Td><span className='color-green'>test@gmail.com</span></Td>
              <Td>0000000000</Td>
              <Td>11/06/2021</Td>
              <Td>32 m 59 s</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>
                <CustomSelect 
                  options={[{value: 0, label: "Action"}]}
                />
              </Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'><Icon icon="fluent-mdl2:circle-addition" color='#81C313' />11<br/>1062</span></Td>
              <Td>Yanis<br/><span className='badge'>949129</span></Td>
              <Td>Yanis</Td>
              <Td><span className='color-green'>test@gmail.com</span></Td>
              <Td>0000000000</Td>
              <Td>11/06/2021</Td>
              <Td>32 m 59 s</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>
                <CustomSelect 
                  options={[{value: 0, label: "Action"}]}
                /> 
              </Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'><Icon icon="fluent-mdl2:circle-addition" color='#81C313' />11<br/>1062</span></Td>
              <Td>Yanis<br/><span className='badge'>949129</span></Td>
              <Td>Yanis</Td>
              <Td><span className='color-green'>test@gmail.com</span></Td>
              <Td>0000000000</Td>
              <Td>11/06/2021</Td>
              <Td>32 m 59 s</Td>
              <Td><span className='badge'>Actif</span></Td>
              <Td>
                <CustomSelect 
                  options={[{value: 0, label: "Action"}]}
                /> 
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <NavigateBar />
    </>
  )
}

export default ClientlistTable;