function BoxCa() {
  return (
    <div className="box box-ca">
      <h3>Chiffre d’affaires du cabinet</h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">Ce mois-ci <span className="badge">24,64 %</span></span>

          <span className="primary-text">10 942,80 $</span>
          <span className="sub-text">vs 10 999,80 $</span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text">14 841,96 $</span>
        </div>
        <div>
          <span className="sub-title-gray">Aujourd'hui <span className="badge">24,64 %</span></span>

          <span className="primary-text">10 079,90 $</span>
          <span className="sub-text">vs 10 999,80 $</span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text">1 678,15 $</span>
        </div>
      </div>
    </div>
  );
}

export default BoxCa;