import { Icon } from "@iconify/react";
import { CloudServiceManagement } from "@carbon/icons-react";
import { BiVideo  } from "react-icons/bi";
import { MdCalendarMonth, MdVerified } from "react-icons/md";
import { HiOutlineChartSquareBar, HiOutlineArrowRight } from "react-icons/hi";
import { FaCalculator, FaUserAlt } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function BatMenu(props) {
  const navigate = useNavigate();
  const [dropDown1, setDropDown1] = useState(false)
  const [dropDown2, setDropDown2] = useState(true)
  const [dropDown3, setDropDown3] = useState(true)

  return (
    <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
      <div>
        <span className="title">ADMINISTRATEUR</span>
        <ul className="menu">
          <li className="selected" onClick={() => navigate("/admin/")}>
            <Icon icon="ion:logo-tableau"/>
            <span>Tableau de bord</span>
          </li>
          <li onClick={() => setDropDown1(!dropDown1)}>
            <CloudServiceManagement />
            <span>Gestion</span>
          </li>
          <li className={`shift ${dropDown1 ? "open" : ""}`} onClick={() => navigate("/admin/cabinetConfiguration")}>
            <HiOutlineArrowRight />
            <span>Configuration</span>
          </li>
          <li className={`shift ${dropDown1 ? "open" : ""}`} onClick={() => navigate("/admin/adminAccount")}>
            <HiOutlineArrowRight />
            <span>Page Compte admin</span>
          </li>
          <li  onClick={() => navigate("/admin/consultation")}>
            <BiVideo />
            <span>Consultation en course</span>
          </li>
          <li onClick={() => navigate("/admin/planning")}>
            <MdCalendarMonth />
            <span>Planning</span>
          </li>
          <li onClick={() => navigate("/admin/experts")}>
            <MdVerified />
            <span>Experts</span>
          </li>
          <li onClick={() => navigate("/admin/analyseExpert")}>
            <HiOutlineChartSquareBar />
            <span>Statistiques</span>
          </li>
          <li onClick={() => navigate("/admin/purchasePackage")}>
            <FaCalculator />
            <span>Compte de résultat</span>
          </li>
          <li>
            <FaUserAlt />
            <span>Client</span>
          </li>
          <li className={`shift ${dropDown2 ? "open" : ""}`}  onClick={() => navigate("/admin/clientList")}>
            <HiOutlineArrowRight />
            <span>Client list</span>
          </li>
          <li className={`shift ${dropDown2 ? "open" : ""}`} onClick={() => navigate("/admin/advice")}>
            <HiOutlineArrowRight />
            <span>Avis</span>
          </li>
          <li>
            <Icon icon="fluent-mdl2:product-list" />
            <span>Produis</span>
          </li>
          <li className={`shift ${dropDown3 ? "open" : ""}`} onClick={() => navigate("/admin/configurationPackage")}>
            <HiOutlineArrowRight />
            <span>List des produis</span>
          </li>
          <li className={`shift ${dropDown3 ? "open" : ""}`}  onClick={() => navigate("/admin/promoCode")}>
            <HiOutlineArrowRight />
            <span>Code promo</span>
          </li>
          <li className={`shift ${dropDown3 ? "open" : ""}`}  onClick={() => navigate("/admin/analyseClient")}>
            <HiOutlineArrowRight />
            <span>Stats produis</span>
          </li>
        </ul>
      </div>

      <div>
        <span className="title">AUTRE</span>
        <ul className="menu">
          <li>
            <IoIosSettings />
            <span>Paramètres</span> 
          </li>
          <li>
            <Icon icon="mingcute:exit-fill" />
            <span>Se déconnecter</span> 
          </li>
        </ul>
      </div>
    </div>
  );
}

function BatMenuExpert(props) {
  const navigate = useNavigate();
  const [dropDown1, setDropDown1] = useState(false)

  return (
    <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
      <div>
        <span className="title">EXPERT</span>
        <ul className="menu">
          <li className="selected" onClick={() => navigate("/expert/")}>
            <Icon icon="ion:logo-tableau"/>
            <span>Tableau de bord</span>
          </li>
          <li  onClick={() => navigate("/expert/planning")}>  
            <MdCalendarMonth />
            <span>Planning</span>
          </li>
          <li  onClick={() => navigate("/expert/mymessages")}>
            <Icon icon="material-symbols:mark-unread-chat-alt-outline-rounded" />
            <span>Messages</span>
          </li>
          <li onClick={() => navigate("/expert/consultation")}>
            <BiVideo />
            <span>Consultation</span>
          </li>
          <li onClick={() => navigate("/expert/advice")}>
            <Icon icon="material-symbols:star-outline" />
            <span>Avis</span>
          </li>
          <li onClick={() => navigate("/expert/statistique")}>
            <HiOutlineChartSquareBar />
            <span>Statistiques</span>
          </li>
          <li onClick={() => navigate("/expert/profile")}>
            <FaUserAlt />
            <span>Profile</span>
          </li>
          <li onClick={() => navigate("/expert/mails")}>
            <Icon icon="material-symbols:mail-outline-rounded" />
            <span>Mails</span>
          </li>
        </ul>
      </div>

      <div>
        <span className="title">AUTRE</span>
        <ul className="menu">
          <li>
            <IoIosSettings />
            <span>Paramètres</span> 
          </li>
          <li>
            <Icon icon="mingcute:exit-fill" />
            <span>Se déconnecter</span> 
          </li>
        </ul>
      </div>
    </div>
  );
}

function BatMenuClient(props) {
  const navigate = useNavigate();
  const [dropDown1, setDropDown1] = useState(false)

  return (
    <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
      <div>
        <span className="badge purple">
          Solde<br/>00:00:00
        </span>
        <span className="title">Client</span>
        <ul className="menu">
          <li onClick={() => navigate("/client/")}>
            <Icon icon="mdi:account"/>
            <span>Mon profil</span>
          </li>
          <li  onClick={() => navigate("/client/expertList")}>  
            <Icon icon="simple-icons:googlecolab"/>
            <span>Je consulte</span>
          </li>
          <li  onClick={() => navigate("/client/myconsultations")}>
            <Icon icon="ic:baseline-message" />
            <span>Mes consultations</span>
          </li>
          <li onClick={() => navigate("/client/mypurchase")}>
            <Icon icon="solar:cart-5-line-duotone"/>
            <span>Mes achats</span>
          </li>
          <li onClick={() => navigate("/client/payement")}>
            <Icon icon="material-symbols:euro" />
            <span>Créditer mon compte</span>
          </li>
          <li onClick={() => navigate("/client/mymeeting")}>
            <Icon icon="ic:baseline-message"/>
            <span>Mes randez-vous</span>
          </li>
          <li onClick={() => navigate("/client/mymethodspayement")}>
            <Icon icon="ic:baseline-payment"/>
            <span>Mes moyens de paiement</span>
          </li>
        </ul>
      </div>

      <div>
        <span className="title">AUTRE</span>
        <ul className="menu">
          <li>
            <IoIosSettings />
            <span>Paramètres</span> 
          </li>
          <li>
            <Icon icon="mingcute:exit-fill" />
            <span>Se déconnecter</span> 
          </li>
        </ul>
      </div>
    </div>
  );
}

export {BatMenuExpert, BatMenuClient};
export default BatMenu;