import { useNavigate } from "react-router-dom";

function BoxConsultations() {
  const navigate = useNavigate();

  return (
    <div className="box box-consultations">
      <h3 className="cursor" onClick={() => {navigate('consultation')}}>Consultations</h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">Ce mois-ci</span>

          <span className="primary-text">946</span>
          <span className="sub-text">(12m)</span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text">3 456</span>
          <span className="sub-text">(12m)</span>
        </div>
        <div>
          <span className="sub-title-gray">Aujourd'hui</span>

          <span className="primary-text">85</span>
          <span className="sub-text">(11m)</span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text">131</span>
          <span className="sub-text">(11m)</span>
        </div>
      </div>
    </div>
  );
}

export default BoxConsultations;