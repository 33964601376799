function HomeExpertTable(props) {
  return (
    <table>
        <thead>
          <tr>
            <td colSpan={3}>Gains</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>Privé</th>  
            <th>{props.data[0][0]}</th>
            <th>{props.data[0][1]}</th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th>{props.data[1][0]}</th>
            <th>{props.data[1][1]}</th>
          </tr>  
        </tbody>  
      </table> 
  )
}

export default HomeExpertTable;