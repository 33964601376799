import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import NavigateBar from './navigateBar';
import { Icon } from '@iconify/react';


function ExpertsTable() {
  return (
    <>
      <div className='tableContainer'>
        <Table>
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Nom Professionnel</Th>
              <Th>Présentation</Th>
              <Th>E-mail</Th>
              <Th>Téléphone</Th>
              <Th>Annonce de présentation</Th>
              <Th>Statut</Th>
              <Th>Action</Th>
              
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td><span className='color-green'>1809</span></Td>
              <Td><div className='icon'><Icon icon="bi:person-circle" width={24} height={24} color="#81C313" /><div>Yanis<br/>1704</div></div></Td>
              <Td><span className='badge'>CBFo</span>(tel)</Td>
              <Td>yanis1@mail.com</Td>
              <Td>0000000000</Td>
              <Td><span className='badge'>Privé</span></Td>
              <Td><span className='badge-green'>Libre</span></Td>
              <Td className="spaceHeight"><span className='badge'>supprimer</span><br/><span className='badge-green'>modifier</span></Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'>1810</span></Td>
              <Td><div className='icon'><Icon icon="bi:person-circle" width={24} height={24} color="#81C313" /><div>Yanis<br/>1704</div></div></Td>
              <Td><span className='badge'>CBFo</span>(tel)</Td>
              <Td>yanis1@mail.com</Td>
              <Td>0000000000</Td>
              <Td><span className='badge'>Privé</span></Td>
              <Td><span className='badge-green'>Libre</span></Td>
              <Td className="spaceHeight"><span className='badge'>supprimer</span><br/><span className='badge-green'>modifier</span></Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'>1811</span></Td>
              <Td><div className='icon'><Icon icon="bi:person-circle" width={24} height={24} color="#81C313" /><div>Yanis<br/>1704</div></div></Td>
              <Td><span className='badge'>CBFo</span>(tel)</Td>
              <Td>yanis1@mail.com</Td>
              <Td>0000000000</Td>
              <Td><span className='badge'>Privé</span></Td>
              <Td><span className='badge-green'>Libre</span></Td>
              <Td className="spaceHeight"><span className='badge'>supprimer</span><br/><span className='badge-green'>modifier</span></Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'>1812</span></Td>
              <Td><div className='icon'><Icon icon="bi:person-circle" width={24} height={24} color="#81C313" /><div>Yanis<br/>1704</div></div></Td>
              <Td><span className='badge'>CBFo</span>(tel)</Td>
              <Td>yanis1@mail.com</Td>
              <Td>0000000000</Td>
              <Td><span className='badge'>Privé</span></Td>
              <Td><span className='badge-green'>Libre</span></Td>
              <Td className="spaceHeight"><span className='badge'>supprimer</span><br/><span className='badge-green'>modifier</span></Td>
            </Tr>
            <Tr>
              <Td><span className='color-green'>1813</span></Td>
              <Td><div className='icon'><Icon icon="bi:person-circle" width={24} height={24} color="#81C313" /><div>Yanis<br/>1704</div></div></Td>
              <Td><span className='badge'>CBFo</span>(tel)</Td>
              <Td>yanis1@mail.com</Td>
              <Td>0000000000</Td>
              <Td><span className='badge'>Privé</span></Td>
              <Td><span className='badge-green'>Libre</span></Td>
              <Td className="spaceHeight"><span className='badge'>supprimer</span><br/><span className='badge-green'>modifier</span></Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
      <NavigateBar />
    </>
  )
}

export default ExpertsTable;