import Layout from "../../components/layout/layout";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";

import { Calendar } from "primereact/calendar"
import { useState } from "react";
import AnalyseclientTable from "../../components/tables/analyseclientTable";
import CustomSelect from "../../components/utils/CustomSelect";

function AnalyseClientPage() {
  const [dateRange, setDateRange] = useState(null)

  return (
    <Layout id="analyseClient">
      <div className="top-bar">
        <h2>Analyse Clients</h2>
        <div className="pagination">
          <Link to="/" className="link">Accueil</Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">Analyse Clients</Link>
        </div>
      </div>

      <div className="filter-bar">
        <div className="date">
          <label htmlFor="inputRange">Date</label>
          <Calendar
            dateFormat="dd MM, yy"
            value={dateRange}
            onChange={(e) => setDateRange(e.value)}
            selectionMode="range"
            id="inputRange"
          />
        </div>
        <div className="recherche">
          <input type="text" placeholder="Rechercher"/>
          <CustomSelect 
            options={[
              {value: 0, label: "Type"},
              {value: 1, label: "1"},
              {value: 2, label: "2"}
            ]}
          />
        </div>
      </div>

      <AnalyseclientTable />
    </Layout>
  );
}

export default AnalyseClientPage;
