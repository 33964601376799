function NavLink(props) {
    return (
        <div className="navLink">
            <div className={props.selected === 0 ? "selected" : ""}>
                Presentation 
            </div>
            <div className={props.selected === 1 ? "selected" : ""}>
                Forfaits
            </div>
            <div className={props.selected === 2 ? "selected" : ""}>
                Commentaires
            </div>
        </div>
    );
}

export default NavLink;