import { LayoutExpert } from "../../components/layout/layout";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import CustomSelect from "../../components/utils/CustomSelect";

function Conversation() {
  const navigate = useNavigate();

  return (
    <LayoutExpert id="conversation">
      <div className="top-bar-back">
        <Icon icon="humbleicons:arrow-go-back" onClick={() => navigate("/expert/mymessages")} />
      </div>
      <div className="chat-box">
        <div className="top-bar">
          <div className="user">
            <HiOutlineUserCircle />
            <div className="user-name">
              <span className="name">User 1</span>
              <span className="actif">Active</span>
            </div>
          </div>
        </div>
      </div>
      <div className="zone">
        <div className="converssation">
          <div className="disc left">
            <span className="date">11:18 PM</span>
            <div className="bulle-audio">
              <div className="start">
                <Icon icon="material-symbols:play-arrow" />
              </div>
              <div className="bar"></div>
              <span className="time">00:08</span>
            </div>
          </div>
          <div className="disc">
            <span className="date">11:18 PM</span>
            <div className="bulle">
              Sample text. Click to select the text box.  Click again or double click to start editing the text.
            </div>
          </div>
          <div className="disc left">
            <span className="date">11:18 PM</span>
            <div className="bulle">
              Sample text. Click to select the text box.  
            </div>
          </div>
          <div className="disc">
            <span className="date">11:18 PM</span>
            <div className="bulle">
              Sample text. Click to select the text box.  Click again or double click to start editing the text.
            </div>
          </div>
          <div className="disc left">
            <span className="date">11:18 PM</span>
            <div className="bulle">
              Sample text. Click to select the text box.  Click again or double click to start editing the text.
            </div>
          </div>
          <div className="disc">
            <span className="date">11:18 PM</span>
            <div className="bulle">
              Sample text. Click to select the text box.  Click again or double click to start editing the text.
            </div>
          </div>
          <div className="disc">
            <span className="date">11:18 PM</span>
            <div className="bulle">
              Sample text. Click to select the text box.  Click again or double click to start editing the text.
            </div>
          </div>
        </div>
        <div className="form">
          <div className="nowrap">
            <CustomSelect 
              options={[
                {value: 0, label: "SMS"}
              ]}
            />
            <input placeholder="Ecrivez votre message ici."/>
          </div>
          <div className="btnGroup">
            <button>Send</button>
          </div>
        </div>
      </div>
    </LayoutExpert>
  );
}

export default Conversation;