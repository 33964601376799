const { Icon } = require("@iconify/react");

function ForfaitBox(props) {
    return (
        <div className="block">
            <div className="preview">
                <div className="text">
                    Forfait Flash
                </div>
            </div>
            <span className="forfait">Forfait ideal pour aborder un domaine en particulier </span>
            <div className="price">
                {props.price} $ / <span className="text">per {props.time} minutes</span>
            </div>
            <button className="btn">Choisir ce forfait {">"}</button>
            <hr />
            <div className="list">
                <Icon icon="material-symbols:nest-clock-farsight-analog-outline-rounded" color="#D00000"/>
                <span>Forfait pouvant etre reparti en plusieurs consultations et avec</span>
            </div>
            <div className="payement">
                <Icon icon="material-symbols:add-shopping-cart" color="#F25858" />
                <span>Paiement securise</span>
            </div>
            <ul>
                <li>Utilisable par telephone <Icon icon="mdi:telephone" color="#F25858" /></li>
                <li>ou par chat <Icon icon="mdi:message-outline" color="#F25858" /></li>
            </ul>
        </div>
    )
}

export default ForfaitBox;