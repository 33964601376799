import { Icon } from "@iconify/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md"
import { GiHamburgerMenu } from "react-icons/gi"
import { useState } from "react";
import CustomSelect from "../utils/CustomSelect";


function Header(props) {
  const [offLine, setOffline] = useState("online")

  return (
    <header className="header">

      <GiHamburgerMenu className="burger-menu" onClick={() => props.setShowBatMenu(!props.showBatMenu)} />

      <img src="/assets/logo.svg" className="logo" alt="logo" />
      
      <ul className="menu">
        <li className="choice">
          <CustomSelect 
            options={[
              {value: "online", label: "Online"},
              {value: "offline", label: "Offline"}
            ]}
            value={"Online"}
            onChange={(e) => setOffline(e.toLowerCase())}
          />
          <label htmlFor="select" className={offLine}></label>
        </li>
        <li className="languge">
          <Icon icon="ic:round-language" /> <span>English</span>
        </li>
        <li className="notification" data-nbnotif="4">
          <IoMdNotificationsOutline />
        </li>
        <li className="profile">
          <img src="/assets/profile.png" alt="profile" className="profile-image" />
          <span className="profile-name">Lucas Denov</span>
          <MdKeyboardArrowDown />
        </li>
      </ul>
    </header>
  );
}

function HeaderClient(props) {
  return (
    <header className="header">
      <GiHamburgerMenu className="burger-menu" onClick={() => props.setShowBatMenu(!props.showBatMenu)} />

      <img src="/assets/logo.svg" className="logo" alt="logo" />
      
      <ul className="menu">
        <li className="languge">
          <Icon icon="ic:round-language" /> <span>English</span>
        </li>
        <li className="notification" data-nbnotif="4">
          <IoMdNotificationsOutline />
        </li>
        <li className="profile">
          <img src="/assets/profile.png" alt="profile" className="profile-image" />
          <span className="profile-name">Lucas Denov</span>
          <MdKeyboardArrowDown />
        </li>
      </ul>
    </header>
  );
}

export { HeaderClient };
export default Header;