import { LayoutClient } from "../../components/layout/layout";
import ConsultationsTable from "../../components/tables/consultationsTable";

function MyConsultationsClient() {
  return (
    <LayoutClient id="myConsultationsClient">
      <h2>Mes consultations</h2>
    
      <ConsultationsTable />
    </LayoutClient>
  );
}

export default MyConsultationsClient;