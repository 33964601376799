import { useState } from "react";
import BatMenu, { BatMenuExpert, BatMenuClient } from "./batMenu";
import Header, { HeaderClient, HeaderExpert } from "./header";

function Layout(props) {
  const [showBatMenu, setShowBatMenu] = useState(false);

  return (
    <>
      <Header showBatMenu={showBatMenu} setShowBatMenu={setShowBatMenu} />
      <div id="split-window">
        <BatMenu showBatMenu={showBatMenu} setShowBatMenu={setShowBatMenu} />
        <main id={props.id} className={`${showBatMenu ? "open" : ""}`}>
          {props.children}
          <footer>Copyright © 2023 | ENTREPRISE SAS - Tous droits reservés.</footer>
        </main>
      </div>
    </>
  );
}

function LayoutExpert(props) {
  const [showBatMenu, setShowBatMenu] = useState(false);

  return (
    <>
      <Header showBatMenu={showBatMenu} setShowBatMenu={setShowBatMenu} />
      <div id="split-window">
        <BatMenuExpert showBatMenu={showBatMenu} setShowBatMenu={setShowBatMenu} />
        <main id={props.id} className={`${showBatMenu ? "open" : ""}`}>
          {props.children}
          <footer>Copyright © 2023 | ENTREPRISE SAS - Tous droits reservés.</footer>
        </main>
      </div>
    </>
  );
}

function LayoutClient(props) {
  const [showBatMenu, setShowBatMenu] = useState(false);

  return (
    <>
      <HeaderClient showBatMenu={showBatMenu} setShowBatMenu={setShowBatMenu} />
      <div id="split-window">
        <BatMenuClient showBatMenu={showBatMenu} setShowBatMenu={setShowBatMenu} />
        <main id={props.id} className={`${showBatMenu ? "open" : ""}`}>
          {props.children}
          <footer>Copyright © 2023 | ENTREPRISE SAS - Tous droits reservés.</footer>
        </main>
      </div>
    </>
  );
}

export { LayoutExpert, LayoutClient };
export default Layout;