import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

function MeetingTable() {
  return (
    <div className='tableContainer'>
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>PRESTATION</Th>
            <Th>DURÉE</Th>
            <Th>EXPERT</Th>
            <Th>ÉTAT</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td className="color-red">13/03/2023</Td>
            <Td>Text</Td>
            <Td>45:00</Td>
            <Td className="color-green">Text</Td>
            <Td className="color-purple">Text</Td>
          </Tr>
          <Tr>
            <Td className="color-red">13/03/2023</Td>
            <Td>Text</Td>
            <Td>45:00</Td>
            <Td className="color-green">Text</Td>
            <Td className="color-purple">Text</Td>
          </Tr>
          <Tr>
            <Td className="color-red">13/03/2023</Td>
            <Td>Text</Td>
            <Td>45:00</Td>
            <Td className="color-green">Text</Td>
            <Td className="color-purple">Text</Td>
          </Tr>
          <Tr>
            <Td className="color-red">13/03/2023</Td>
            <Td>Text</Td>
            <Td>45:00</Td>
            <Td className="color-green">Text</Td>
            <Td className="color-purple">Text</Td>
          </Tr>
          <Tr>
            <Td className="color-red">13/03/2023</Td>
            <Td>Text</Td>
            <Td>45:00</Td>
            <Td className="color-green">Text</Td>
            <Td className="color-purple">Text</Td>
          </Tr>
          <Tr>
            <Td className="color-red">13/03/2023</Td>
            <Td>Text</Td>
            <Td>45:00</Td>
            <Td className="color-green">Text</Td>
            <Td className="color-purple">Text</Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
}

export default MeetingTable;